.order {
  position: relative;
  height: calc(100vh - 77px);
}

.order-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order__title {
  text-align: center;
  font-family: 'Benzin-Regular';
  font-size: 36px;
  line-height: 49px;
}

.order__icon {
  width: 93px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.order__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Benzin-Regular';
  width: 221px;
  height: 58px;
  color: #ebebdf;
  background-color: #e9631a;
  font-size: 18px;
  border-radius: 64px;
}
