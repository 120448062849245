.header {
  z-index: 10;
  position: fixed;
  background-color: #acc7da;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__list {
  display: flex;
  gap: 32px;
}
.header__list-item {
  cursor: pointer;
  padding: 10px;
}

.header__burger {
  display: none;
}

.header__burger-social {
  display: none;
}

@media (max-width: 768px) {
  .header {
    height: 77px;
    justify-content: flex-start;
  }
  .header__mobile {
    display: flex;
    gap: 32px;
    margin: 0 auto;
  }
  .header__burger {
    width: 20px;
    position: relative;
    height: 15px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .header__burger::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    /* Создаем дополнительную область вокруг кнопки */
  }

  .header__burger-line-one {
    height: 1.2px;
    width: 20px;
    background-color: black;
    position: absolute;
    top: 0%;
    transition: 0.2s;
    opacity: 1;
  }

  .header__burger-line-two {
    height: 1px;
    width: 20px;
    background-color: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    transition: 0.3s;
  }

  .header__burger-line-three {
    height: 1.2px;
    width: 20px;
    background-color: black;
    position: absolute;
    bottom: 0;
    opacity: 1;
    transition: 0.2s;
  }

  .header__burger-line-one-active {
    opacity: 0;
  }

  .header__burger-line-two-open-one {
    transform: rotate(45deg);
  }

  .header__burger-line-two-open-two {
    transform: rotate(-45deg);
  }

  @keyframes slideInFromTop {
    from {
      opacity: 0;
      transform: translateY(100%); /* початкове зміщення за межі екрану */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* кінцеве положення на екрані */
    }
  }

  @keyframes slideOutToBottom {
    from {
      opacity: 1;
      transform: translateY(0); /* початкове положення на екрані */
    }
    to {
      opacity: 0;
      transform: translateY(100%); /* зміщення за межі екрану */
    }
  }

  .header__modal {
    position: fixed;
    top: 72px; /* висота хедера */
    left: 0;
    width: 100vw;
    height: calc(100vh - 72px);
    background: linear-gradient(to bottom, #d1e0ea, #acc7da);
    z-index: 9;
    overflow-y: auto;
    box-sizing: border-box;
    animation: slideInFromTop 0.5s ease forwards;
  }

  .header__modal-exit {
    animation: slideOutToBottom 0.5s ease forwards;
  }

  .header__modal-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 26px;
    font-family: 'Benzin-Regular';
    font-size: 24px;
    line-height: 33px;
    gap: 46px;
  }
}
