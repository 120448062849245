@font-face {
  font-family: "Benzin-Bold";
  font-weight: 400;
  font-style: normal;
  src: local("Benzin-Bold"),
    url(../public/fonts/Benzin-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Benzin-Regular";
  font-weight: 400;
  font-style: normal;
  src: local("Benzin-Regular"),
    url(../public/fonts/Benzin-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: local("Montserrat"),
    url(../public/fonts/Montserrat.ttf) format("opentype");
}

body {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Benzin-Regular", sans-serif;
}
