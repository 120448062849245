.form {
  min-height: calc(100vh - 165px - 72px);
  max-width: 690px;
  margin: 0 auto;
}

.form__header {
  width: 100%;
  height: 72px;
  background-color: #acc7da;
}

.form__back {
  cursor: pointer;
  display: flex;
  gap: 12px;
  padding-top: 46px;
  padding-bottom: 10px;
  margin-bottom: 26px;
}

.form__back-img {
  height: 16px;
}
.form__back-text {
  font-size: 16px;
}

.form__title {
  font-family: 'Benzin-Regular', sans-serif;
  text-align: center;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 32px;
}

.form-wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 400px;
  margin: 0 auto;
}

.form__input {
  width: 100%;
  height: 56px;
  padding-left: 23px;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  border: 1px solid #262626;
  border-radius: 8px;
  background-color: #ebebdf;
}

.form__btn {
  cursor: pointer;
  background-color: #e9631a;
  color: #ebebdf;
  width: 315px;
  height: 58px;
  border-radius: 64px;
  margin: 0 auto 16px;
}

@media (max-width: 768px) {
  .form {
    min-height: calc(100vh - 179px - 77px);
  }
  .form__header {
    height: 77px;
  }
  .form__back {
    padding-top: 56px;
    margin-bottom: 16px;
    margin-left: 26px;
  }
  .form__title {
    font-size: 20px;
    line-height: 28px;
    max-width: 300px;
    margin: 0 auto 32px;
  }
  .form-wrap {
    gap: 24px;
    max-width: none;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  .form__btn {
    max-width: none;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}
