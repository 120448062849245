.footer {
  position: sticky;
  display: flex;
  height: 165px;
  width: 100%;
  align-items: center;
  padding-left: 67px;
  padding-right: 65px;
  background-color: #acc7da;
}

.footer__list {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.footer__list-item--icon {
  display: flex;
  gap: 32px;
}

.footer__list-item-icon {
  display: block;
  cursor: pointer;
  height: 28px;
  width: 28px;
}

@media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 24px 30px;
  }
  .footer__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
}
