.main {
  padding-top: 72px;
}
.main__top {
  color: #ebebdf;
  width: 100%; /* Ширина контейнера */
  height: calc(100vh - 72px); /* Висота контейнера */
  background-image: url('../../../public/img/1desck.jpg');
  background-size: cover;
  background-position: 70% center; /* Зміщення на 10% вліво */
  background-repeat: no-repeat;
}
.main__top-text--wrap {
  padding-top: 215px;
  margin-left: 95px;
  max-width: 498px;
}

.main__top-title {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 48px;
  line-height: 66px;
}

.main__top-title-text {
  font-family: 'Benzin-Regular', sans-serif;
  font-size: 32px;
  width: 608px;
  line-height: 44px;
  margin-bottom: 12px;
}
.main__top-title-text--next {
  font-size: 20px;
  margin-bottom: 56px;
  font-weight: 300;
}

.main__top-text--small {
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: 300;
}

.main__top-btn {
  cursor: pointer;
  width: 498px;
  height: 58px;
  background-color: #e9631a;
  border-radius: 64px;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.main__top-btn-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}
.main__top-btn-text--decoration {
  margin-top: 8px;
  text-decoration: line-through;
}

.main__step {
  margin-top: 64px;
}

.main__step-reg {
  position: relative;
  max-width: 885px;
  margin: 0 auto;
}

.main__step-reg-arrow {
  position: absolute;
  right: 30px;
  top: 40px;
  width: 75px;
  height: 136px;
  animation: shake 4s ease-in-out infinite;
  /* animation-delay: 1s; затримка перед повторенням анімації */
}
@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg); /* початкова позиція */
  }
  35% {
    transform: rotate(-10deg); /* нахил в одну сторону */
  }
  75% {
    transform: rotate(10deg); /* нахил в іншу сторону */
  }
}

.main__step-reg-title {
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  margin-bottom: 32px;
}

.main__step-reg-text-numb {
  font-family: 'Benzin-Bold', sans-serif;
  font-size: 40px;
  color: #e9631a;
}

.main__step-reg-text {
  font-size: 16px;
  text-align: center;
}

.main__step-block {
  position: relative;
  margin-top: 64px;
  margin-bottom: 940px;
}

.main__step-block-title {
  line-height: 50px;
  font-size: 36px;
  text-align: center;
}
.main__step-block-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 64px auto;
  width: fit-content;
}

.main__step-block--left {
  position: relative;
  right: 140px;
}
.main__step-block--right {
  position: relative;
  left: 140px;
}
.main__step-block-text-wrap {
  position: absolute;
  top: 56px;
  left: 28px;
}
.main__step-block-text-wrap--right {
  text-align: right;
  right: 28px;
}

.main__step-block--left-img {
  width: 360px;
}

.main__step-block-number {
  font-family: 'Benzin-Bold', sans-serif;
  color: #e9631a;
  font-size: 62px;
  line-height: 85px;
}
.main__step-block-text {
  font-size: 20px;
}

.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
  bottom: 80px;
}
.z3 {
  z-index: 3;
  bottom: 160px;
}
.z4 {
  z-index: 4;
  bottom: 240px;
}

/* Початкові стилі для блоків ліворуч і праворуч */
.main__step-block--left,
.main__step-block--right {
  opacity: 0;
  transform: translateX(-100px); /* для лівого */
  transition: all 0.6s ease;
}

.main__step-block--right {
  transform: translateX(100px); /* для правого */
}

/* Клас для активації анімації */
.animate-in {
  opacity: 1;
  transform: translateX(0); /* повертає блок на місце */
}

.main__about-title {
  font-size: 36px;
  margin-bottom: 32px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 32px;
}

.main__about-text-img-wrap {
  display: flex;
  height: 773px;
  background-image: url('../../../public/img/2desck.png');
  background-size: cover; /* Розтягування картинки по всій ширині */
  background-position: center 10%; /* Центрування по горизонталі та зміщення на 10% вниз */
  background-repeat: no-repeat; /* Заборона повторення картинки */
}
.main__about-text-img {
  width: 50%;
}

.main__about-text-wrap {
  text-align: right;
  color: black;
}

.main__about-text-wrap-text {
  width: 50%;
  margin-right: 95px;
  padding-top: 114px;
  margin-left: auto;
  text-align: center;
}

.main__about-text-title {
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.main__about-text {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 59px;
}

.main__about-list-title {
  font-size: 36px;
  line-height: 50px;
}

.main__about-list-item-icon {
  width: 25px;
}

.main__about-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}

.main__about-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin-bottom: 24px;
  gap: 8px;
}

.main__about-list-item--big {
  width: 286px;
}

.main__lesson {
  margin-top: 64px;
}

.main__lesson-title {
  font-size: 36px;
  line-height: 50px;
  margin-left: 95px;
  margin-bottom: 32px;
}

.main__lesson-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 95px;
  padding-right: 50px;
  gap: 24px;
  margin-bottom: 60px;
}

.main__lesson-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 411px;
  height: 196px;
  border-radius: 8px;
  background: linear-gradient(180deg, #d1e0ea, #acc7da);
}

.main__lesson-list-item-icon {
  height: 26px;
}
.main__lesson-list-item-text {
  width: 250px;
  text-align: center;
}

.main_center-img {
  margin-bottom: 64px;
  height: 695px;
}

.main__structure-title {
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  margin-bottom: 32px;
}

.main__structure-list {
  max-width: 780px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
}

.main__structure-list-item {
  cursor: pointer;
  background-color: #c8dae6;
  border-radius: 20px;
  min-height: 72px;
  position: relative;
}

.main__structure-list-item--anim {
  position: absolute;
  right: 17px;
  top: 20px;
  font-size: 40px;
  transition: transform 1500ms ease;
}

.rotate {
  transform: rotate(90deg); /* Два оберти за 2 секунди */
}

.rotate-back {
  transform: rotate(-90deg); /* Два оберти назад */
}

.main__structure-list-item-title {
  margin-left: 12px;
  margin-top: 32px;
  padding-right: 35px;
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 500;
}

.main__structure-list-item-text--wrap {
  margin-top: 10px;
  padding-bottom: 24px;
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 300;
  list-style: disc;
  padding-left: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out, opacity 0.4s ease-out;
  opacity: 0;
}

.main__structure-list-item-text {
  list-style: disc;
  margin-left: 12px;
}
.main__structure-list-item-text--wrap.open {
  max-height: 500px; /* Задайте максимальне значення, достатнє для всього тексту */
  opacity: 1;
}

.main__teach {
  margin-top: 64px;
}

.main__teach-title {
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  margin-bottom: 32px;
}
.main__teach-block-wrap {
  height: 208px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.main__teach-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.main__teach-block--down {
  position: relative;
  top: 51px;
}

.main__teach-block--up {
  position: relative;
  top: 20px;
}

.main__teach-block-cicle {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #e9631a;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Benzin-Bold', sans-serif;
  font-size: 32px;
  color: #ebebdf;
  line-height: 44px;
}
.main__teach-block-text {
  width: 244px;
  text-align: center;
  font-size: 16px;
  line-height: 19.5px;
}

.main__teach-block-line--1 {
  position: absolute;
  width: 100%;
  border-top: 1px solid black;
  top: 30%;
}
.main__teach-block-line--2 {
  position: absolute;
  width: 100%;
  border-top: 1px solid black;
  top: 15.1%;
}
.main__teach-block-line--3 {
  position: absolute;
  width: 100%;
  border-top: 1px solid black;
  top: 39.6%;
}
.main__teach-block-line--4 {
  position: absolute;
  width: 100%;
  border-top: 1px solid black;
  top: 15.1%;
}
.main__teach-block-line--5 {
  position: absolute;
  width: 100%;
  border-top: 1px solid black;
  top: 39.6%;
}

.main__question-title {
  font-size: 36px;
  line-height: 49px;
  margin-top: 64px;
  text-align: center;
}
.main__question-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 32px;
}
.main__question-list-item--q {
  padding-top: 32px;
  padding-left: 12px;
  padding-right: 30px;
}

.main__question-list-item--a {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  margin-top: 10px;
  padding-bottom: 24px;
  margin-left: 12px;
  transition: max-height 0.4s ease, opacity 0.4s ease;
}

.main__question-list-item--a.show {
  max-height: 100px; /* Достатнє значення для вмісту */
  opacity: 1;
}
.main__question-list-item {
  cursor: pointer;
  position: relative;
  width: 758px;
  min-height: 72px;
  background-color: #c8dae6;
  border-radius: 20px;
  font-size: 16px;
  line-height: 19.5px;
}

.main__question-list-item--anim {
  position: absolute;
  right: 17px;
  top: 32px;
  font-size: 40px;
  transition: transform 1500ms ease;
}

.main_bottom-btn {
  cursor: pointer;
  display: block;
  margin: 64px auto;
  width: 441px;
  height: 58px;
  background-color: #e9631a;
  border-radius: 64px;
  color: #ebebdf;
  text-transform: uppercase;
}
.main_bottom-img {
  height: 776px;
}

@media (max-width: 768px) {
  .main {
    padding-top: 77px;
  }
  .main__top {
    color: black;
    height: calc(100vh - 77px); /* Висота контейнера */
    background-image: linear-gradient(to bottom, #d1e0ea00 40%, #acc7da 100%), url('../../../public/img/1inst.png'); /* Шлях до картинки */
    background-position: center 35%; /* Центрування картинки */
  }
  .main__top-text--wrap {
    padding-top: 46px;
    margin-left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
  }
  .main__top-title {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 55px;
    text-transform: none;
  }
  .main__top-title-text {
    margin: 0 auto;
    font-size: 16px;
    max-width: 384px;
    width: auto;
    line-height: 22px;
  }
  .main__top-title-text--next {
    margin-bottom: auto;
    margin-top: 8px;
    font-weight: 300;
    font-size: 16px;
  }
  .main__top-text--small {
    font-size: 16px;
    margin-bottom: 20px;
    margin: 0 auto;
    margin-bottom: 14px;
  }
  .main__top-btn {
    color: #ebebdf;
    width: 100%;
    font-size: 15px;
    margin-bottom: 13px;
  }
  .main__top-btn-text {
    font-size: 13px;
    line-height: 16px;
  }
  .main__top-btn-text--decoration {
    margin-top: 8px;
    margin-bottom: 24px;
  }
  .main__step {
    margin-top: 32px;
  }
  .main__step-reg {
    position: relative;
    width: 100%;
    padding: 0 28px 0 16px;
  }
  .main__step-reg-arrow {
    right: 15px;
    top: 4px;
    width: 54px;
    height: 95px;
  }
  .main__step-reg-title {
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    max-width: 300px;
  }
  .main__step-reg-text-numb {
    font-size: 24px;
  }
  .main__step-reg-text {
    text-align: left;
    margin-bottom: 24px;
  }

  .main__step-block {
    margin-top: 32px;
    margin-bottom: 565px;
  }
  .main__step-block-title {
    padding: 0 20px;
    line-height: 28px;
    font-size: 20px;
    text-align: center;
  }
  .main__step-block-wrap {
    margin: 40px auto;
  }

  .main__step-block--left {
    right: 60px;
  }
  .main__step-block--right {
    left: 60px;
  }
  .main__step-block-text-wrap {
    top: 24px;
    left: 24px;
  }
  .main__step-block-text-wrap--right {
    text-align: right;
    right: 24px;
  }
  .main__step-block--left-img {
    max-width: 230px;
    width: 230px;
  }
  .main__step-block-number {
    font-size: 40px;
    line-height: 55px;
  }
  .main__step-block-text {
    font-size: 16px;
    line-height: 19px;
  }
  .z2 {
    z-index: 2;
    bottom: 50px;
  }
  .z3 {
    z-index: 3;
    bottom: 100px;
  }
  .z4 {
    z-index: 4;
    bottom: 150px;
  }
  .main__about-title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .main__about-text-wrap {
    text-align: right;
    color: black;
    height: 447px;
    background-image: linear-gradient(to bottom, #d1e0ea00 40%, #acc7da 100%), /* Градієнт поверх зображення */ url('../../../public/img/2inst.png'); /* Зображення */
    background-color: transparent;
    background-size: cover; /* Розтягування картинки по всій ширині */
    background-position: center 35%; /* Центрування картинки */
    background-repeat: no-repeat; /* Заборона повторення картинки */
  }
  .main__about-text-wrap-text {
    width: 591px;
    margin-right: 95px;
    padding-top: 114px;
    margin-left: auto;
    text-align: center;
  }
  .main__about-text-title--wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .main__about-text-title {
    text-align: center;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: auto;
  }
  .main__about-text {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    text-align: center;
    padding: 0 8px;
  }
  .main__about-list-title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 16px;
    font-size: 20px;
    line-height: 24px;
  }
  .main__about-list {
    margin-top: 16px;
  }
  .main__about-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 286px;
    width: auto;
    margin-bottom: 32px;
    text-align: center;
  }
  .main__photo-mob {
    height: 205px;
  }
  .main__lesson {
    margin-top: 32px;
  }
  .main__lesson-title {
    font-size: 20px;
    line-height: 28px;
    margin: 0 auto 24px;
    text-align: center;
    max-width: 260px;
  }
  .main__lesson-list {
    padding-left: 16px;
    padding-right: 16px;
    gap: 16px;
    margin-bottom: 64px;
  }
  .main__lesson-list-item {
    min-width: 350px;
    height: 140px;
    font-size: 14px;
    line-height: 19px;
  }
  .main__lesson-list-item-text {
    width: 260px;
    text-align: center;
  }
  .main__structure-title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .main__structure-list {
    max-width: auto;
    width: 100%;
    display: flex;
    padding: 0 16px;
  }
  .main__teach-title {
    font-size: 20px;
    line-height: 28px;
    max-width: 278px;
    margin: 0 auto 24px;
  }
  .main__teach-block-wrap {
    justify-content: start;
    width: auto;
    overflow-y: hidden;
  }
  .main__question-title {
    font-size: 20px;
    line-height: 28px;
  }
  .main__question-list {
    margin-top: 24px;
    padding: 0 16px;
  }
  .main__question-list-item {
    width: 100%;
  }
  .main_bottom-btn-wrap {
    padding: 0 16px;
  }
  .main_bottom-btn {
    width: 100%;
  }
  .main__structure-list-item--anim {
    right: 10px;
  }
}
